/*--------------------------------------------------------------
# Common styles
--------------------------------------------------------------*/

@font-face {
	font-family: 'Pacifico';
	font-style: normal;
	font-weight: 400;
	src: local('Pacifico Regular'), local('Pacifico-Regular'),
		url(https://fonts.gstatic.com/s/pacifico/v12/FwZY7-Qmy14u9lezJ-6H6MmBp0u-.woff2) format('woff2');
	font-display: swap;
}

html {
	height: 100%;
}

body {
	font-family: "Open Sans", sans-serif;
	color: #444444;
	height: 100%;
}

.center {
	text-align: center;
}

.inline-block {
	display: inline-block;
}


.my-btn {
	background: linear-gradient(90deg, #f44336, #b71c1c);
	font-weight: 600;
	font-size: large;
	padding: 8px 10px;
	border-radius: 8px;
	outline: none;
}

.my-btn:hover {
	background: none;
	background-color: #d32f2f;
}

.my-btn:active {
	background-color: #d32f2f;
	border: 1px solid;
}

.page-title {
	font-weight: 600;
}

ul,
ol {
	list-style-position: outside !important;
}

a {
	color: #009cea;
	text-decoration: none;
}

a:hover {
	color: #1eb4ff;
	text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: "Raleway", sans-serif;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
	height: 60px;
	transition: all 0.5s;
	z-index: 100;
	transition: all 0.5s;
	background: #fff;
	margin: 0;
	padding-right: 0 !important;
}

#header {
	box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

#header .logo h1 {
	font-size: 28px;
	margin: 0;
	line-height: 0;
	font-weight: 600;
	letter-spacing: 1px;
}

#header .logo h1 a,
#header .logo h1 a:hover {
	color: #576971;
	text-decoration: none;
}

#header .logo img {
	padding: 0;
	margin: 0;
	max-height: 40px;
	text-align: end;
}

#header .logo {
	min-width: 350px;
}

#header .logo-img {
	margin: auto;
}

#header .logo-title {
	padding: 0;
	color: #01257D;
	font-family: Arial, Helvetica, sans-serif;
	vertical-align: middle;
}

#header .logo-title .title {

	font-size: 22px;
	font-weight: 600;
}

#header .logo-title .caption {
	font-weight: 500;
	font-size: 12px;
	font-style: italic;

}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation 
*/
.navbar {
	padding: 0;
}

.navbar ul {
	margin: 0;
	padding: 0;
	display: flex;
	list-style: none;
	align-items: center;
}

.navbar li {
	position: relative;
	margin: 0 3px;
}

.navbar li .main-link:after {
	content: '';
	display: block;
	width: 0;
	height: 3px;
	background-color: blue;
	/* Underline color */
	position: absolute;
	bottom: -2px;
	left: 0;
	transition: width 0.3s ease;
}

.navbar li .main-link:hover:after {
	width: 100%;
	/* Full width on hover */
}

.navbar li .active:after {
	width: 100%;
}

.navbar a,
.navbar a:focus {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px 12px 10px 12px;
	font-size: 17px;
	font-family: sans-serif;
	color: black;
	white-space: nowrap;
	transition: 0.3s;
}

.navbar a i,
.navbar a:focus i {
	font-size: 12px;
	line-height: 0;
	margin-left: 5px;
}

.navbar .dropdown ul {
	display: block;
	position: absolute;
	left: 14px;
	top: calc(100% + 30px);
	margin: 0;
	padding: 10px 0;
	z-index: 99;
	opacity: 0;
	visibility: hidden;
	background: #fff;
	box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
	transition: 0.3s;
}

.navbar .dropdown ul li {
	min-width: 200px;
}

.navbar .dropdown ul a {
	padding: 10px 20px;
	font-size: 14px;
	color: #003651;
}

.navbar .dropdown ul a i {
	font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover>a {
	color: #0d47a1;
}

.navbar .dropdown:hover>ul {
	opacity: 1;
	top: 100%;
	visibility: visible;
}

.navbar .dropdown .dropdown ul {
	top: 0;
	left: calc(100% - 30px);
	visibility: hidden;
}

.navbar .dropdown .dropdown:hover>ul {
	opacity: 1;
	top: 0;
	left: 100%;
	visibility: visible;
}

@media (max-width: 1366px) {
	.navbar .dropdown .dropdown ul {
		left: -90%;
	}

	.navbar .dropdown .dropdown:hover>ul {
		left: -100%;
	}
}

@media screen and (min-width: 992px) {
	.navbar .contact-link {
		color: white !important;

		background: linear-gradient(90deg, #3f51b5, #1a237e);
		border-radius: 8px;
		padding: 8px 15px;
		margin: 0 20px;
		font-size: 15px;
	}

	.navbar .contact-link:focus {
		padding: 8px 15px !important;
		margin: 0 20px;
		font-size: 15px;
	}
}

/**
* Mobile Navigation 
*/
.mobile-nav-toggle {
	color: #364146;
	font-size: 28px;
	cursor: pointer;
	display: none;
	line-height: 0;
	transition: 0.5s;
}

.mobile-nav-toggle.bi-x {
	color: #fff;
}

@media (max-width: 991px) {
	.mobile-nav-toggle {
		display: block;
	}

	.navbar ul {
		display: none;
	}
}

.navbar-mobile {
	position: fixed;
	overflow: hidden;
	top: 0;
	right: 0;
	/* max-width: 500px; */
	max-height: 500px;
	width: 100%;
	height: 100%;
	background: rgba(32, 38, 41, 0.9);
	transition: 0.3s;
	z-index: 999;
}

.navbar-mobile .mobile-nav-toggle {
	position: absolute;
	top: 15px;
	right: 15px;
}

.navbar-mobile ul {
	display: block;
	position: absolute;
	top: 55px;
	right: 15px;
	bottom: 15px;
	left: 15px;
	padding: 10px 0;
	background-color: #fff;
	overflow-y: auto;
	transition: 0.3s;
}

.navbar-mobile a,
.navbar-mobile a:focus {
	padding: 10px 20px;
	font-size: 15px;
	color: #364146;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover>a {
	color: #0d47a1;
}

.navbar-mobile li>a {
	color: black !important;
}

.navbar-mobile li>a:hover {
	color: blue !important;
}

.navbar-mobile li .main-link:after {
	height: 0 !important;
}

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
	margin: 15px;
}

.navbar-mobile .dropdown ul {
	position: static;
	display: none;
	margin: 10px 20px;
	padding: 10px 0;
	z-index: 99;
	opacity: 1;
	visibility: visible;
	background: #fff;
	box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
	min-width: 200px;
}

.navbar-mobile .dropdown ul a {
	padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
	font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover>a {
	color: #0d47a1;
}

.navbar-mobile .dropdown>.dropdown-active {
	display: block;
}

/*--------------------------------------------------------------
# Hero Section - Backkground Images
--------------------------------------------------------------*/

.home-bg {
	background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
		url("../images/home-cover.jpg");
}

.solutions-bg {
	background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
		url("../images/factory.png");
}

.process-optimization-bg {
	background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
		url("../images/factory.png");
}

.technological-solutions-bg {
	background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
		url("../images/services/car-robot.jpg");
}

.about-bg {
	background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
		url("../images/about/clay-banks-LjqARJaJotc-unsplash.jpg");
}

.sustainability-bg {
	background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
		url("../images/services/windmill.jpg");
}

.contact-bg {
	background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
		url("../images/contact-us/hello-on-phone.jpg");
}

.contact-us-bar-bg {

	background: linear-gradient(90deg, #3f51b5, #1a237e);
	color: white;
}

.hero {
	/* width: 100%; */
	background-repeat: no-repeat;
	background-size: cover;
	background-position: right top;
	background-attachment: fixed;
	/* background-size: 100%; */
	margin-top: 60px;
	padding: 60px 0;
	min-height: 600px;
	text-align: center;
}

.hero h1 {
	margin: 150px 0 20px 0;
	font-size: 48px;
	font-weight: 700;
	line-height: 56px;
	color: white;
}

.hero h2 {
	color: white;
	margin-bottom: 30px;
	font-size: 24px;
}

.hero .hero-img {
	text-align: center;
}

.hero .hero-img img {
	width: 70%;
}

@media (max-width: 992px) {

	.hero h1 {
		font-size: 32px;
		line-height: 40px;
	}

	.hero h2 {
		font-size: 20px;
	}
}


/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
	padding: 60px 0;
	overflow: hidden;
}

.grey-bg {
	background-color: #eeeeee !important;
}

.section-title {
	text-align: center;
	padding-bottom: 30px;
}

.section-title h2 {
	font-size: 36px;
	font-weight: 500;
	margin-bottom: 20px;
	padding-bottom: 0;
	font-family: "Poppins", sans-serif;
	color: #21282b;
}

.section-title p {
	margin-bottom: 0;
}

/*--------------------------------------------------------------
# Home
--------------------------------------------------------------*/

.home-page .service {
	position: relative;
	overflow: hidden;
	/* margin: 0; */
	transition: all 0.3s ease-in-out;
	border-radius: 10px;
	/* margin: 20px 0; */
	padding: 5px;
}

.home-page .service img {
	max-width: 100%;
	width: 100%;
}

.home-page .service .description {
	/* margin: 20px; */
	padding: 20px;
	font-size: 18px;
}

/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about {
	padding: 0;
	margin-top: 40px;
}

@media (max-width: 992px) {
	.about .container {
		padding: 30px;
	}
}

.about .content h3 {
	font-weight: 400;
	font-size: 32px;
	color: #364146;
	font-family: "Poppins", sans-serif;
}

.about .content ul {
	list-style: none;
	padding: 0;
}

.about .content ul li {
	padding-bottom: 10px;
}

.about .content ul i {
	font-size: 20px;
	padding-right: 4px;
	color: #28a745;
}

.about .content p:last-child {
	margin-bottom: 0;
}

#guiding-lights .cards {
	margin: auto;
	width: 75%;
}

.who-we-are p {
	font-size: 18px;
	font-weight: 400;
}

i {
	color: #0d47a1;
}

.why-choose-us .cards {
	margin: auto;
	width: 75%;
}

/*--------------------------------------------------------------
# Cards
--------------------------------------------------------------*/
.cards .icon-box {
	/* padding: 30px; */
	position: relative;
	overflow: hidden;
	/* margin: 0; */
	background: #fff;
	box-shadow: 0 10px 29px 0 rgba(68, 88, 144, 0.1);
	/* transition: all 0.3s ease-in-out; */
	/* border-radius: 4px; */
	text-align: center;
	/* border-bottom: 3px solid #fff; */
	padding-bottom: 20px;
}

.cards .icon-img {
	height: 300px;
	width: 100%;
	/* height: 100%; */
	object-fit: cover;
}

.cards .icon i {
	font-size: 48px;
	line-height: 1;
	margin-bottom: 15px;
}

.cards .title {
	font-weight: 700;
	margin-bottom: 15px;
	font-size: 25px;
}

.cards .title a {
	color: #111;
}

.cards .description {
	font-size: 15px;
	line-height: 26px;
	padding: 0 30px;
	margin-bottom: 30px;
}

.solution-img {
	width: 80%;
	margin: auto;
}

.solution-text ol,
ul {
	list-style: none;
}

.solution-text ol {
	margin-left: 0;
	padding: 0;
}

.solution-text ul {
	list-style: inside;
}

.horizontal-card img {
	margin: auto;
	width: 100%;
	height: auto;
	border-radius: 10px;
}

.horizontal-card {
	box-shadow: 0 10px 29px 0 rgba(68, 88, 144, 0.1);
	padding: 20px 0;
}

.horizontal-card .description {
	line-height: 1.8;
}

.icon i {
	margin: 50px;
	padding: 20px;
}

@media screen and (max-width: 991px) {
	.horizontal-card img {
		margin-top: 10px;
	}

	.horizontal-card {
		padding: 0 !important;
	}

	.horizontal-card .description {
		text-align: center;
		margin-top: 20px;
		padding: 15px;
	}
}

/*--------------------------------------------------------------
# Team
--------------------------------------------------------------*/
.team {
	background-size: cover;
	padding: 60px 0;
	/* position: relative; */
}

.team::before {
	content: "";
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 9;
}

.team img {
	width: 100%;
}

.team .member {
	text-align: center;
	margin-bottom: 80px;
	position: relative;
	width: 100%;
	
	box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
	background: #fff;
	border-radius: 5px;
	filter: grayscale(0%);
	transition: box-shadow 0.5 ease;
	cursor: pointer;
}

.team .member .view-details {
	position: absolute;
	top: 45%;
	left: 37%;
	visibility: hidden;
	color: white;
	text-decoration: underline;
	transition: visibility 0.2s;
}

.team .member .pic {
	background-color: black;
}

.team .member:hover{
	box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.1);
}

.team .member:hover img{
	opacity: 0.5;
}

.team .member:hover .view-details{
	visibility: visible;
}

.team .member .pic {
	width: 100%;
	height: 210px;
	object-fit: cover;
	overflow: hidden;
}

@media screen and (max-width: 768px){
	.team .member .pic {
		height: 350px !important;
	}
}



@media screen and (min-width: 768px) and (max-width: 992px){
	.team .member .pic {
		height: 300px !important;
	}
}

@media screen and (min-width: 1400px){
	.team .member .pic {
		height: 300px !important;
	}
}


.team .member .pic img {
	transition: all 0.5s ease;

}

.team .member .pic img:hover {	
	transform: scale(1.05);
}



.team .member .member-info {
	/* position: absolute; */
	padding: 20px 10px;
	color: #364146;
	/* max-height: 150px; */
	/* overflow: hidden; */
	/* transition: max-height 0.5s ease-in-out; */
}

.team .member h4 {
	font-weight: 700;
	margin-bottom: 10px;
	font-size: 16px;
	color: #364146;
	position: relative;
	padding-bottom: 10px;
}

.team .member h4::after {
	content: "";
	position: absolute;
	display: block;
	width: 50px;
	height: 1px;
	background: #b5c1c6;
	bottom: 0;
	left: calc(50% - 25px);
}

.team .member span {
	font-style: italic;
	display: block;
	font-size: 13px;
}

.team .member .social {
	margin-top: 15px;
}

.team .member .social a {
	transition: color 0.3s;
	color: #627680;
}

.team .member .social a:hover {
	color: #009cea;
}

.team .member .social i {
	font-size: 16px;
	margin: 0 2px;
}

@media (max-width: 992px) {
	.team .member {
		margin-bottom: 110px;
	}

	.team .member .pic {
		height: 400px;
	}
}

#member-details-modal {
	visibility: hidden;
	position: absolute;
	top: 100px;
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/

@media (max-width: 991px) {
	.contact .container {
		padding: 0px;
	}
}

.contact .info {
	/* padding: 30px; */
	width: 100%;
	/* border: 2px solid #e9ecee; */
	border-radius: 4px;
	word-wrap: break-word;
}

.contact .info .text {
	font-size: 18px;
	color: black;
	font-weight: 300;
}

.contact .info i {
	font-size: 20px;
	/* color: #009cea; */
	float: left;
	width: 44px;
	height: 44px;
	background: #f2f4f5;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50px;
	transition: all 0.3s ease-in-out;
}

.contact .info h4 {
	padding: 0 0 0 60px;
	font-size: 20px;
	font-weight: 600;
	margin-bottom: 5px;
	color: #364146;
}

.contact .info p {
	padding: 0 0 10px 60px;
	margin-bottom: 20px;
	font-size: 14px;
	color: #627680;
}

.contact-us-text {
	font-size: 18px;
	padding: 10px;
}

@keyframes animate-loading {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

@media (max-width: 991px) {
	#contact .container {
		padding: 30px;
	}
}

.tally-form {	
	box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.1);
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
.footer {
	background-color: #263238;
	color: white;
	padding: 20px 50px;
}

.footer .footer-link {
	color: white;
	margin-bottom: 5px;
	display: block;
	/* transition: font-weight 0.5s ease; */
}

.footer .footer-link:hover {
	font-weight: 520;
}

@media screen and (min-width: 990px) {
	.solutions .container {
		padding: 60px;
	}
}

/* Modal open adds padding right to header */

body.modal-open{
    overflow: inherit !important;
    padding-right:0 !important;
}

.modal-dialog {
    max-width: 70%;
    margin: 2rem auto;
}

.modal-col-1 {
	padding: 0 30px;
}

.modal-member-img img {

	clip-path: circle(50% at 50% 50%);
	width: 100%;
}

.modal-member-description {
	padding-left: 30px ;
	/* padding-right: 10px; */
}

.modal-member-info {
	/* position: absolute; */
}

.modal li {
	display: list-item;
	list-style-type: disc;
}

.modal-header {
    border-bottom: 0 none;
}